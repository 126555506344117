.mobile__menu-button {
    width: 26px;
    height: 22px;
    background: url('../../assets/icon/mobile-menu-icon.svg');
    cursor: pointer;
}

.mobile__menu-button-close {
    display: block;
    width: 26px;
    height: 26px;
    margin-right: 18px;
    background: url('../../assets/icon/mobile-menu-close-icon.svg');
    cursor: pointer;
    margin-left: auto;
}

.mobile__menu-item-block {
    position: absolute;
    background: #FFF;
    padding: 16px 0px;
    right: 0px;
    top: 0px;
    width: 100%;
    height: 100vh;
    overflow: scroll;
    z-index: 100;
}

.mobile__menu-buttons-block {
    margin-top: 16px;
}

.mobile__menu-element-button {
    width: 100%;
    /* border-top: 1px solid #BE4330; */
    border-bottom: 1px solid #BE4330;
    cursor: pointer;
    background: #FFFFFF;
    padding: 12px;

    font-family: 'Alumni Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 25px;
    color: #BE4330;

    transition: .3s ease;
}

.mobile__menu-element-button:hover {
    background: #BE4330;
    color: #FFF;
}

.mobile__menu-element-button.active {
    background: #F5E5C7;
    color: #333;
    border: none;
}

.mobile__menu-element-button.sub {
    background: #BE4330;
    color: #FFF;
}

.mobile__menu-element-button.sub:hover {
    background: #fc8472;
    color: #FFF;
}